<template>
    <div>
        <div class="card basic-info">
            <div class="name">對象姓名</div>
            <div class="value">
                <span>{{personal.patientName}}</span>
                <div class="verification">已獲機構認證</div>
            </div>
            <div class="name">所患疾病</div>
            <div class="value">
                <span>{{personal.patientDisease}}</span>
                <div class="verification">
                    {{this.times(personal.timeDiagnosis)}}在{{personal.locationDiagnosis}}確診，已獲機構認證
                </div>
            </div>
            <div class="name">收款賬戶</div>
            <div class="value">
                <span>{{personal.cardholder}}<span v-if="personal.cardholder == personal.patientName">（患者本人）</span></span>
                <div class="verification">身份證已獲機構驗證</div>
                <div class="verification">收款戶口已獲機構驗證</div>
            </div>
        </div>
        <h2>發起人承諾</h2>
        <p>
            發起人已承諾所提交的文字與圖片資料完全真實，無任何虛構事實及隱瞞真相的情況，如有不實，發起人願承擔所有法律責任。
        </p>
        <h2>平台聲明</h2>
        <p>
            所有籌款資訊由信息發佈者提供，本平台會盡力核實但不能確保信息的真實性，在捐款前應自行判斷風險。
        </p>
        <div class="card friends">
            <div class="title" v-if="friendProvements.length">朋友認證</div>
            <div class="title" v-else>還沒朋友認證</div>
            <div class="friend-container"
                v-for="provement in friendProvements"
                :key="provement.id">
                <div class="name">{{ provement.name }}</div>
                <div class="verification" v-if="provement.verified">
                    已獲機構認證
                </div>
                <div class="content">
                    {{ provement.content }}
                </div>
            </div>
            <button class="apply" @click="handleAdd">申請認證</button>
            <br />
            <el-pagination
                    v-if="page_total"
                    background
                    layout="prev, pager, next"
                    :total="page_total"
                    @prev-click="prevPage"
                    @next-click="nextPage"
                    @current-change="handleCurrentChange">
            </el-pagination>
        </div>

        <el-dialog title="進行驗證" v-model="dialogFormVisible">
            <el-form ref="form" :model="form" :rules="rules">
                <el-form-item prop="real_name">
                    <el-input v-model="form.real_name" placeholder="請輸入身份證上的名字" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item prop="auth_message">
                    <el-input type="textarea" v-model="form.auth_message" style="margin-top: 10px;" :rows="2" placeholder="請輸入驗證訊息（例如：我是他親妹妹，請幫幫她！）"></el-input>
                </el-form-item>
                <el-form-item prop="auth_related_files">
                    <el-upload
                    class="upload-demo"
                    multiple
                    action="#"
                    :limit="3"
                    accept="image/png, image/jpeg, image/jpg, application/pdf"
                    :on-remove="(file, fileList)=>{return otherHandleRemove(file, fileList)}"
                    :on-exceed="handleExceed"
                    :http-request="requestUpload"
                    :show-file-list="true"
                    :before-upload="beforeAvatarUpload"
                    :before-remove="beforeRemove"
                    :file-list="form.auth_related_files">
                    <el-button size="small" type="primary">點擊上傳</el-button>
                    </el-upload>
                    <div slot="tip" class="el-upload__tip">請上傳相關資料（只能上傳jpg/png/pdf文件，且不超過500kb）</div>
                </el-form-item>
            </el-form>
            <div slot="footer" style="text-align: center;margin-top: 35px;">
                <el-button type="danger" @click="submitForm" round>進行驗證</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import api from "@/api";

export default {
    data() {
        return {
            dialogFormVisible:false,
            personal: [],
            fundraise_id: undefined,
            form: {
                fundraise_id: undefined,
                real_name: undefined,
                auth_message: undefined,
                auth_related_files: []
            },
            friendProvements: [],
            // 總頁數
            page_total:0,
            // 當前頁數
            antorbital_page:1,
            // 每頁條數
            page_number:5,
            i:0,
            j:0,
            statusVerified: '1',
            // 表单校验
            rules: {
                real_name: [
                    { required: true, message: "姓名不能為空", trigger: "blur" }
                ],
                auth_message: [
                    { required: true, message: "認證訊息不能為空", trigger: "blur" }
                ],
                auth_related_files: [
                    { required: true, message: "相關資料不能為空", trigger: "blur" }
                ]
            },
        };
    },
    methods: {
      times(time){
        var date = new Date(time);
        var y = date.getFullYear();
        var m = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
        var d = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
        return y+'年'+m+'月'+d+'日';
      },
      requestUpload(){},
      handleExceed(files, fileList) {
        this.$message.warning(`當前限制選擇 3 個文件，本次選擇了 ${files.length} 個文件，共選擇了 ${files.length + fileList.length} 個文件`);
      },
      otherHandleRemove(file, fileList) {
        let files = this.form.auth_related_files;
        files.forEach((item, index) => {
          if (file.name == item.name) {
            files.splice(index, 1);
          }
        });
      },
      beforeRemove(file, fileList) {
        return this.$confirm(`確定移除 ${ file.name }？`);
      },
      beforeAvatarUpload(file) {
        let types = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
        let isUploadFile = types.includes(file.type);
        let fileSizeFlag = file.size / 1024 / 1024  < 2 ? true : false;
        if (! isUploadFile) {
          this.$message.error('上傳文件只能是jpg、png、jpeg、pdf格式');
          return false;
        }
        if(! fileSizeFlag) {
          this.$message({message: '上傳文件大小不能超過2m',type: 'error'});
          return false;
        }else{
         this.loading = true;
          let formData = new FormData();
          formData.append("file", file);
          formData.append("source", '籌款上傳');
          let that = this;
                 that.$axios({
                    url:process.env.VUE_APP_API_SERVER+'/front/savefile/',
                    method: 'post',
                    data:formData,
                    header: {
                        "Content-Type": "multipart/form-data"
                    }          
                }).then(function(res){
                    let save_file = {name: res.data.data.name, file: res.data.data.file_url};
                    that.form.auth_related_files.push(save_file);
                    that.loading = false;
                }).catch(function(err){
                    that.loading = false;
                })
            }
      },
      handleAdd: function() {
          this.dialogFormVisible = true
          this.form = {
              fundraise_id: this.fundraise_id,
              real_name: undefined,
              auth_message: undefined,
              auth_related_files: []
          }
          if (this.$refs["form"]) {
              this.$refs["form"].resetFields();
          }
      },
      submitForm: function() {
          this.$refs["form"].validate(valid => {
              if (valid) {
                  api.fundraiseAuth.addFundraiseAuth(this.form).then(response => {
                      this.dialogFormVisible = false;
                      this.$message.success('本平台已收到閣下的申請');
                      this.antorbital_page =1;
                      this.page_total =0;
                      this.i =0;
                      this.j =0;
                      this.contents_list();
                  });
              }
          });
      },
        handleCurrentChange(Page){
            this.i = 1;
            this.j = 0;
            this.antorbital_page = Page;
            this.contents_list();
        },
        prevPage(){
            this.j = 1;
            this.antorbital_page -=1;
            this.contents_list();
        },
        nextPage(){
            this.j = 1;
            this.antorbital_page +=1;
            this.contents_list();
        },
        contents_list(){
            if((this.j != 1 && this.i != 1) || (this.j == 0 || this.i == 0)){
                api.fundraiseAuth.getFundraiseAuth('?fundraise='+this.fundraise_id+'&pageNum='+this.antorbital_page+'&pageSize='+this.page_number).then(response => {
                    if(response.code == 200){
                        let content_list = response.data.results;
                        this.page_total = Math.ceil(response.data.count / this.page_number) * 10;

                        for(let i=0; i<content_list.length; i++){
                            content_list[i].name =content_list[i].realName;
                            content_list[i].content =content_list[i].authMessage;
                            content_list[i].verified =(content_list[i].status ==this.statusVerified ?true: false);
                        }

                        this.friendProvements = content_list;
                    }else{
                        this.$message.error(response.msg);
                    }
                }).catch(error=>{
                    this.$message.error('數據異常，請重新獲取！');
                });
            }
        }
    },
    created:function (){
        this.loading = true;
        let id = this.$route.params.id;
        api.fundraise.getFundraiseDetails(id).then(response => {
            this.personal = response.data.personal;
            this.fundraise_id =response.data.id;
            this.contents_list();
            this.loading = false;
        }).catch(error=>{
            this.loading = false;
            this.$message.error('基本資料數據異常，請重新獲取！');
        });
    }
};
</script>

<style lang="scss" scoped>
p,
.card,
h2 {
    margin-bottom: 16px;
    line-height: 1.2;
}
.card {
    background-color: $green-100;
    padding: 16px;
    .name,
    .title {
        font-weight: 600;
        line-height: 1.2;
    }
    .verification {
        color: $green-500;
        font-size: 14px;
        @include fr-s-c;
        &::before {
            flex-shrink: 0;
            content: "";
            width: 8px;
            height: 8px;
            background-color: $green-500;
            border-radius: 4px;
            display: block;
            margin-right: 4px;
        }
    }
}
.basic-info {
    display: grid;
    grid-template-columns: 10vw 1fr;
    grid-template-rows: auto auto auto;
    gap: 8px;
    .value {
        @include fc-s-s;
        line-height: 1.2;
    }
}
.friends {
    @include fc-s-st;
    position: relative;
    .title {
        margin-bottom: 16px;
    }
    .friend-container {
        margin-bottom: 8px;
        line-height: 1.5;
        .content {
            color: $gray-400;
        }
    }
    .apply {
        width: 200px;
        color: $white;
        background-color: $green-500;
        border-radius: 8px;
        font-size: 16px;
        line-height: 32px;
        height: 32px;
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
    }
}
h2 {
    font-size: 24px;
    font-weight: 600;
}
@media screen and (max-width: 900px) {
    p,
    .card,
    h2 {
        margin-bottom: 8px;
    }
    h2 {
        font-size: 18px;
    }
    .basic-info {
        grid-template-columns: 80px auto;
    }
    .friends {
        .apply {
            width: 80%;
            max-width: 200px;
            position: static;
            align-self: center;
        }
    }
}
</style>